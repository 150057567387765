<template functional>
  <div :id="data.id" :class="['factbanner', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <div class="container">
      <div class="indented-text">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FactBanner'
}
</script>

<style lang="scss">
// mobile
$factbanner-padding-y: $component-padding-y !default;
$factbanner-padding-x: 0 !default;
$factbanner-bg: $beige !default;

$factbanner-color: $brown !default;
$factbanner-heading-font-weight: $font-weight-light !default;
$factbanner-paragraph-font-size: $font-size-lg !default;
$factbanner-paragraph-letter-spacing: $letter-spacing-1 !default;

$factbanner-item-gap: $component-padding-y !default;

// desktop
$factbanner-breakpoint: $desktop-breakpoint !default;

$factbanner-item-desktop-gap: $component-desktop-padding-y !default;

.factbanner {
  padding: $factbanner-padding-y $factbanner-padding-x;
  background-color: $factbanner-bg;
  color: $factbanner-color;

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: $factbanner-heading-font-weight;
  }

  p {
    font-size: $factbanner-paragraph-font-size;
    letter-spacing: $factbanner-paragraph-letter-spacing;
  }

  .fact-item {
    display: flex;
    margin-bottom: $factbanner-item-gap;

    .eyecatcher-icon {
      margin-bottom: 0;
    }
  }

  &.not-indented .indented-text {
    margin: 0;

    > .h1, .h2, .h3, .h4, .h5, .h6 {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include media-breakpoint-up($factbanner-breakpoint) {
    .fact-item {
      margin-top: $factbanner-item-desktop-gap * 0.5;
      margin-bottom: $factbanner-item-desktop-gap;
    }
  }
}
</style>
